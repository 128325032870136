import { useEffect, useMemo, useState } from "react";
import { getGroupForecastReq } from "../../api";
import { Loader } from "@/core/components";
import {
  IAstronomy,
  ICurrentDay,
  IWeatherForecastDayResponse,
} from "../../api/interfaces";
import {
  CurrentWeather,
  Forecast,
  HeaderWeather,
  UVIndexProgress,
  WeatherAlerts,
  WeatherTable,
} from "../../components";
import styles from "./styles.module.css";
import { IWeatherAlert } from "../../typing/interface";
import _ from "lodash";
import { getDataForRows } from "../../utils";
import { useGetRows } from "../../hooks";
import { useLanguageStore } from "@/store/language.store";
import moment from "moment";
import { useEventsListener } from "@/hooks/use-listener.hook";
import { MobileHeaderWeather } from "@/weather/components/mobile-weather-header";

export const DetailedWeather = () => {
  const { lang } = useLanguageStore();

  const [showWeather, setShowWeather] = useState<{
    isShow: boolean;
    location: any;
  }>(null);

  const [weather, setWeather] = useState(null);
  const [forecast, setForecast] = useState<IWeatherForecastDayResponse[]>(null);
  const [currentWeather, setCurrentWeather] = useState<ICurrentDay>(null);
  const [astronomy, setAstronomy] = useState<IAstronomy>(null);
  const [isloading, setIsLoading] = useState(false);
  const [alerts, setAlerts] = useState<IWeatherAlert[]>([]);
  const [uvIndex, setUvIndex] = useState(0);

  useEventsListener(
    "weatherDetailed",
    (data) => {
      setShowWeather(data);
    },
    []
  );

  const getWeather = async () => {
    setIsLoading(true);
    try {
      const { data } = await getGroupForecastReq(
        `${showWeather?.location.coordinate.lat},${showWeather?.location.coordinate.lng}`
      );

      setForecast(
        data.forecast.forecastday.filter((it) =>
          moment(it.date).isAfter(moment().format("YYYY-MM-DD"))
        )
      );

      setCurrentWeather(data.current);
      setAstronomy(data.forecast.forecastday[0].astro);

      const forecastHours = data.forecast.forecastday[0].hour;
      const weatherZones = getDataForRows(forecastHours);

      setWeather(weatherZones);

      setAlerts(
        data.alerts.alert.map((it) => ({
          category: it?.category,
          description: it?.desc,
          date: `Expires ${moment(it?.expires).format("MMMM DD")}, ${moment(
            it?.expires
          ).format("hh:mm")}`,
          title: it?.headline,
        }))
      );
      setUvIndex(data.current.uv <= 12 ? data.current.uv : 12);
    } catch (error) {}
    setIsLoading(false);
  };

  const rows = useGetRows(weather);

  useEffect(() => {
    getWeather();
  }, [showWeather?.location?.city]);

  const currentWeatherBlock = useMemo(() => {
    if (!currentWeather) return null;
    return (
      <CurrentWeather
        icon={currentWeather?.condition?.icon}
        last_updated={currentWeather?.last_updated}
        temp_c={currentWeather?.temp_c}
        sunrise={astronomy?.sunrise}
        sunset={astronomy?.sunset}
        realFeel={currentWeather?.feelslike_c}
        wind={{
          dir: currentWeather?.wind_dir,
          kph: currentWeather?.wind_kph,
        }}
        pressure={currentWeather?.pressure_mb}
        humidity={currentWeather?.humidity}
      />
    );
  }, [currentWeather, showWeather?.location, lang]);

  const forecastBlock = useMemo(() => {
    if (!forecast) return;
    return <Forecast forecast={forecast} />;
  }, [forecast, showWeather?.location]);

  if (!showWeather?.isShow) return null;
  else if (isloading) {
    return <Loader />;
  }
  return (
    <div className={styles.layout}>
      <HeaderWeather
        weatherLocation={{
          region: _.defaultTo(showWeather?.location?.region, ""),
          city: _.defaultTo(showWeather?.location?.city, ""),
          country: showWeather?.location?.country,
        }}
      />

      <MobileHeaderWeather
        weatherLocation={{
          region: _.defaultTo(showWeather?.location?.region, ""),
          city: _.defaultTo(showWeather?.location?.city, ""),
          country: showWeather?.location?.country,
        }}
      />
      <div className={styles.content}>
        <div className={styles.row}>
          <>{currentWeatherBlock}</>

          <>{forecastBlock}</>
        </div>

        <div className={styles.row_grid}>
          <WeatherTable data={rows} />
          <div className={styles.options_weather_container}>
            <WeatherAlerts alerts={alerts} />

            <UVIndexProgress uvIndex={uvIndex} />
          </div>
        </div>
      </div>
    </div>
  );
};
