import { appEvents, AppEvents } from "@/core/events";
import { useEffect } from "react";
export const useEventsListener = <T extends keyof AppEvents>(
  name: T,
  action: (data: AppEvents[T]) => void,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const fn = (data: AppEvents[T]) => action(data);
    appEvents.on(name, fn);
    return () => appEvents.off(name, fn);
  }, dependencies);
};
