import { Events } from "jet-tools";

export type AppEvents = {
  event: {
    data: string;
  };

  seaLevel: { level?: number; isShow?: boolean };
  weatherDetailed: { isShow: boolean; location: any };
};
export const appEvents = new Events<AppEvents>();
