import styles from "./styles.module.css";
import { legendRiskConfig } from "./config";
import { LegendItem } from "./atoms/legend-item";
import { useState } from "react";
import _ from "lodash";
import { useCategoryRiskStore } from "@/store/category-risk.store";
import { CategoryRisks, Languages } from "@/typing/enums";
import { Icon } from "@/core/icons";
import { useTranslation } from "react-i18next";
import { useLanguageStore } from "@/store/language.store";
import { MobileLegendRisk } from "./mobile-legend-risk";
import { useResizeWindow } from "@/hooks/use-resize-window.hook";

export const LegendRisk = () => {
  const { t } = useTranslation();
  const { lang } = useLanguageStore();
  const { width: widthWindow } = useResizeWindow();

  const { active } = useCategoryRiskStore();
  const legend = localStorage.getItem("LEGEND");
  const [showLegend, setHowLegend] = useState(JSON.parse(legend));
  const toggleLegend = () => {
    setHowLegend(() => !showLegend);
    localStorage.setItem("LEGEND", JSON.stringify(!showLegend));
  };

  const positionClassLegend = {
    [Languages.DE]: styles.position_de,
    [Languages.EN]: styles.position_en,
    [Languages.ES]: styles.position_en,
    [Languages.FR]: styles.position_fr,
  };

  const itemsRisk = legendRiskConfig.map((it) => {
    return <LegendItem label={t(`${it.label}`)} bgColor={it.color} />;
  });
  if (active?.category === CategoryRisks.Requirements) {
    return null;
  }
  return (
    <>
      <div className={styles.legend_btn}>
        <Icon
          className={styles.icon_legend}
          onClick={toggleLegend}
          name={showLegend ? "x" : "legend"}
          size={24}
          color="#0F0E0E"
        />
      </div>

      {widthWindow <= 767 ? (
        <MobileLegendRisk showLegend={showLegend} toggleLegend={toggleLegend}>
          {itemsRisk}
        </MobileLegendRisk>
      ) : null}
      <div
        id="legend"
        className={`${styles.legend} ${positionClassLegend[lang]} ${
          showLegend ? styles.slide_in : styles.slide_out
        }`}
      >
        {itemsRisk}
      </div>
    </>
  );
};
