import { RiskLevel, SubcategoryRisks } from "@/typing/enums";
import { LeftSideBar } from "../left-side-bar";
import { GoogleMap } from "@/google-map";
import styles from "./styles.module.css";
import { WeatherMap } from "@/weather-map";
import { useCategoryRiskStore } from "@/store/category-risk.store";
import { useMemo } from "react";
import { SeaLevelMapWidget } from "@/sea-level";
import { SliderControlSea } from "../slide-control-sea";
import { LegendRisk } from "../legend-risk";
import { MobileFilter } from "../mobile-filter";
import { MobileHeader } from "../mobile-header";
import { SeaLevelBtn } from "../sea-level-btn";

interface IProps {
  countriesPlaceIds: { [key: string]: RiskLevel };
  handleChangeCode: (val: { code: string; longName: string }) => void;
  hideSidebar: boolean;
  toggleBar: (val: boolean) => void;
  isOpenBar: boolean;
}
export const LayoutPage = ({
  countriesPlaceIds,
  handleChangeCode,
  hideSidebar,
  toggleBar,
  isOpenBar,
}: IProps) => {
  const { active } = useCategoryRiskStore();

  const legendRisk = useMemo(() => {
    if (active?.subcategory === SubcategoryRisks.Weather) {
      return null;
    } else if (active?.subcategory === SubcategoryRisks.SeaLevelRise) {
      return <SliderControlSea />;
    }
    return <LegendRisk />;
  }, [active, length]);

  const map = useMemo(
    () => ({
      google: (
        <GoogleMap
          countriesList={countriesPlaceIds}
          setCountryCode={handleChangeCode}
        />
      ),
      weather: <WeatherMap />,
      seaLevel: <SeaLevelMapWidget />,
    }),
    [active, countriesPlaceIds]
  );

  return (
    <div className={styles.layout}>
      <MobileHeader />
      <SeaLevelBtn />
      <div className={styles.side_block}>
        {hideSidebar ? null : (
          <LeftSideBar isOpen={isOpenBar} toggleBar={toggleBar} />
        )}
        {legendRisk}
        <MobileFilter />
      </div>
      {active?.subcategory === SubcategoryRisks.Weather && (
        <div className={styles.weater_map_container}>{map.weather}</div>
      )}
      <div
        className={
          active?.subcategory === SubcategoryRisks.SeaLevelRise
            ? styles.show_map
            : styles.hide_map
        }
      >
        {map.seaLevel}
      </div>
      <div
        className={`${styles.weater_map_container}
          ${
            active?.subcategory !== SubcategoryRisks.Weather
              ? styles.show_map
              : styles.hide_map
          }`}
      >
        {map.google}
      </div>
    </div>
  );
};
