import { geoCodePlaceReq, getWeatherReq } from "@/api";
import { useEffect, useRef, useState } from "react";
import "./style.css";
import { appEvents } from "@/core/events";
const WORLDS_BOUNDS = {
  north: 85.6,
  south: -60.0,
  east: 180.0,
  west: -185.0,
};

const CENTER_OF_EUROPE = { lat: 50, lng: 25.0 };

const createPopupContent = (
  locationName: string,
  temperature: number,
  icon: string,
  goWeather: () => void
) => {
  // Створення контейнера для попапу
  const container = document.createElement("div");
  container.style.cssText = `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    background-color: white;
  `;

  // Вміст попапу
  container.innerHTML = `
    <div style="display: flex; align-items: center; height:90px; padding-right:10px">
      <img src="https:${icon}" alt="weather-icon" style="width: 60px; height: 50px; margin-right: 10px;" />
      <div style="display: flex; flex-direction: column;">
        <h3 style="margin: 0; font-size: 14px; color: #262933; font-weight:400; line-height:22px">${locationName}</h3>
        <h1 style="font-size: 28px; font-weight:700; line-height:36px; color: #262933;">${temperature}°</h1>
      </div>
    </div>
    <button id="popup-button" style="padding: 7px; background: #0D8F5B; color: white; font-size:14px; line-height:18px; font-weight:500; border: none; border-radius: 48px; cursor: pointer; width:100%">
      View More →
    </button>
  `;

  // Додаємо обробник події на кнопку
  const button = container.querySelector("#popup-button");
  button?.addEventListener("click", goWeather);

  return container;
};
export const WeatherMap = () => {
  const [cityCoordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  }>(null);
  const placesServiceRef = useRef(null);
  const mapRef = useRef(null);

  //weather
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState<any>(null);
  const [activeInfoWindow, setActiveInfoWindow] =
    useState<google.maps.InfoWindow | null>(null);

  useEffect(() => {
    setLocation(null);
    setWeather(null);
  }, []);

  const goDetailedWeather = () => {
    appEvents.emit("weatherDetailed", { isShow: true, location });
  };

  const getWeather = async (coordinate: { lat: number; lng: number }) => {
    try {
      const response = await getWeatherReq(coordinate);
      const { data: place } = await geoCodePlaceReq(coordinate);
      setLocation({
        country: place?.address.country,
        city: place?.address?.city
          ? place?.address?.city
          : place?.address.municipality
          ? place?.address.municipality
          : place?.address.county,
        region: place?.address.region
          ? place?.address.region
          : place?.address.state,
        coordinate,
      });
      const weatherData = response.data;
      setWeather({
        temp: weatherData.current.temp_c,
        condition: weatherData.current.condition.text,
        icon: weatherData.current.condition.icon,
      });
    } catch (error) {
      setLocation(null);
      setWeather(null);
    }
  };
  //-----
  const zoomInButton = document.createElement("div");
  const zoomOutButton = document.createElement("div");

  zoomInButton.className = "custom-zoom-button";
  zoomOutButton.className = "custom-zoom-button";
  const updateIconsColor = (zoom: number) => {
    const zoomOutButtonSVG = `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 12H22" stroke="${
                      zoom === 2.4 ? "#ccc" : "#0F0E0E"
                    }" stroke-width="2" stroke-linecap="round"/>
                </svg>`;
    const zoomInButtonSVG = `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2V22" stroke="${
                      zoom === 7 ? "#ccc" : "#0F0E0E"
                    }" stroke-width="2" stroke-linecap="round"/>
                    <path d="M2 12H22" stroke="${
                      zoom === 7 ? "#ccc" : "#0F0E0E"
                    }" stroke-width="2" stroke-linecap="round"/>
                </svg>`;
    zoomInButton.innerHTML = zoomInButtonSVG;
    zoomOutButton.innerHTML = zoomOutButtonSVG;
  };

  const handleCountryClick = async () => {
    if (activeInfoWindow) {
      activeInfoWindow.close();
    }

    const city = location?.city ? location?.city : location?.region;
    const locationName = `${location?.country} ${city}`;
    const temperature = +weather?.temp.toFixed(0);
    const icon = weather?.icon;

    const content = createPopupContent(
      locationName,
      temperature,
      icon,
      goDetailedWeather
    );
    const newInfoWindow = new google.maps.InfoWindow({
      content,
      position: cityCoordinates,
      headerDisabled: true,
    });
    newInfoWindow.open(mapRef?.current);
    setActiveInfoWindow(newInfoWindow);
  };

  const initMap = async () => {
    const { Map } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;
    //init map
    const mapInstance = new Map(document.getElementById("map"), {
      center: CENTER_OF_EUROPE,
      zoom: 3,
      mapId: "6c5168d0058f979b",
      mapTypeControl: false,
      minZoom: 3,
      maxZoom: 7,
      gestureHandling: "greedy",
      streetViewControl: false,
      fullscreenControl: false,
      restriction: {
        latLngBounds: WORLDS_BOUNDS,
        strictBounds: false,
      },
      zoomControl: false,
      styles: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [{ visibility: "off", cursor: "default" }],
        },
      ],
    });

    placesServiceRef.current = new google.maps.places.PlacesService(
      mapInstance
    );
    mapRef.current = mapInstance;

    //create btns control zoom

    mapInstance.addListener("zoom_changed", updateIconsColor);
    const zoom = mapInstance.getZoom();
    updateIconsColor(zoom);

    zoomOutButton.addEventListener("click", () => {
      if (mapInstance.getZoom() > 2.4) {
        mapInstance.setZoom(mapInstance.getZoom() - 1);
      }
    });
    zoomInButton.addEventListener("click", () => {
      if (mapInstance.getZoom() < 7) {
        mapInstance.setZoom(mapInstance.getZoom() + 1);
      }
    });

    mapInstance.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      zoomOutButton
    );
    mapInstance.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      zoomInButton
    );
    mapInstance.addListener("click", async (e: any) => {
      const lat = e.latLng?.lat();
      const lng = e.latLng?.lng();
      if (lat && lng) {
        setCoordinates({ lat: e?.latLng?.lat(), lng: e?.latLng?.lng() });
        await getWeather({ lat, lng });
      }
    });
  };

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (weather && cityCoordinates) {
      handleCountryClick();
    } else {
      activeInfoWindow?.close();
    }
  }, [weather, cityCoordinates]);

  return <div id="map" style={{ width: "100%", height: "100dvh" }}></div>;
};
