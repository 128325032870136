import { GoBack } from "@/core/components";
import styles from "./styles.module.css";
import fullLogo from "@/assets/images/full-logo.svg";
import { appEvents } from "@/core/events";

interface IWeatherLocation {
  city: string;
  country: string;
  region: string;
}
interface IProps {
  weatherLocation: IWeatherLocation;
}
export const HeaderWeather = ({ weatherLocation }: IProps) => {
  const handleGoBack = () => {
    appEvents.emit("weatherDetailed", { isShow: false, location: null });
  };
  return (
    <div className={styles.container}>
      <div className={styles.left_container}>
        <GoBack onClick={handleGoBack} />

        <img src={fullLogo} className={styles.logo} />

        <p className={styles.label}>
          {weatherLocation.city}{" "}
          <span>{`(${weatherLocation.region} ${weatherLocation.country})`}</span>
        </p>
      </div>
    </div>
  );
};
