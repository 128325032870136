import { PropsWithChildren, StrictMode, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import { Loader } from "./core/components/loader.tsx";

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

// eslint-disable-next-line react-refresh/only-export-components
const LoadGoogleMaps = ({ children }: PropsWithChildren) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry,places&v=weekly&language=en`;
    script.async = true;
    script.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey]);

  return loaded ? <>{children}</> : <Loader />;
};
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <LoadGoogleMaps>
      <App />
    </LoadGoogleMaps>
  </StrictMode>
);
