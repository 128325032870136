import styles from "./styles.module.css";
import { IMenuItem } from "../typing";
import { useTranslation } from "react-i18next";

interface IProps {
  index: number;
  item: IMenuItem;
  onClick: () => void;
  isActive: boolean;
}
export const MenuItem = ({ index, item, onClick, isActive }: IProps) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className={styles.menu_item}
      style={{
        overflow: index == 0 && "hidden",
      }}
    >
      <div className={styles.radio_row}>
        <div
          className={styles.indicator}
          style={{ backgroundColor: isActive ? "#0D8F5B" : "#D7D8DB" }}
        />
        <p
          className={
            isActive
              ? `${styles.label_item} ${styles.active}`
              : styles.label_item
          }
        >
          {t(`${item.label}`)}
        </p>
      </div>
    </div>
  );
};
