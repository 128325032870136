import { http } from "@/services/http.service";
import axios from "axios";
import { IGetRisksParams, IRisksResponse } from "./interfaces";
export const getCapitalReq = (countryCode: string) => {
  return axios.post<any>(
    `https://countriesnow.space/api/v0.1/countries/capital`,
    { country: countryCode }
  );
};

export const getCapitalCoordnatesReq = (capitalName: string) => {
  return axios.get(
    `https://nominatim.openstreetmap.org/search?city=${capitalName}&format=json&limit=1`
  );
};

export const getWeatherReq = (coordinate: { lat: number; lng: number }) => {
  const apiKey = import.meta.env.VITE_WEATHER_API_KEY;
  return axios.get(
    `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${coordinate.lat},${coordinate.lng}`
  );
};

export const geoCodePlaceReq = (coordinate: { lat: number; lng: number }) => {
  return axios.get(
    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate.lat}&lon=${coordinate.lng}&accept-language=en`
  );
};

//req in data base

export const getRisksReq = (params: IGetRisksParams) => {
  return http.get<IRisksResponse>("guest/risks", { params });
};

export const getOwerallRisksReq = () => {
  return http.get<IRisksResponse>("guest/risks/overall");
};
