import { getArticleReq } from "../../api";
import { useEffect, useMemo, useState } from "react";
import { Drawer } from "antd";
import styles from "./styles.module.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ICategoryGroup } from "@/store/category-risk.store";
import { useResizeWindow } from "@/hooks/use-resize-window.hook";
import { SubcategoryRisks } from "@/typing/enums";
import { menuLabels, risksColor } from "@/config/risks.config";
import { Icon } from "@/core/icons";
import { useLanguageStore } from "@/store/language.store";
import { useTranslation } from "react-i18next";

interface IProps {
  countryCode: { code: string; longName: string };
  open: boolean;
  onChangeDrawer: (val: boolean) => void;
  categoryGroup: ICategoryGroup;
}
export const ArticleWidget = ({
  countryCode,
  open,
  onChangeDrawer,
  categoryGroup,
}: IProps) => {
  const { width: widthWindow } = useResizeWindow();
  const [article, setArticle] = useState("");
  const [historyRisks, setHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useLanguageStore();
  const { t } = useTranslation();

  const getArticle = async () => {
    setIsLoading(true);
    try {
      const { data } = await getArticleReq(countryCode.code, {
        ...categoryGroup,
        lang,
      });

      setArticle(data.article.content);
      setHistory(data.risksLevels.sort((a: any, b: any) => b.year - a.year));
    } catch (error: any) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getArticle();
  }, [countryCode, open, article]);

  const risksHistory = useMemo(() => {
    if (categoryGroup?.subcategory !== SubcategoryRisks.Geopolitical)
      return null;
    return (
      <div className={styles.history_container}>
        {historyRisks?.map((it: any) => {
          return (
            <div className={styles.risk_year}>
              <div
                style={{
                  backgroundColor: risksColor[it?.riskLevel],
                  height: 24,
                  width: 24,
                  borderRadius: 110,
                }}
              />
              <p>{it?.year}</p>
            </div>
          );
        })}
      </div>
    );
  }, [historyRisks, categoryGroup?.subcategory, open]);

  const drawerWidth = () => {
    switch (true) {
      case widthWindow < 1024:
        return "100dvw";

      default:
        return "736px";
    }
  };

  const getCategoryLabel = () => {
    if (categoryGroup?.category && categoryGroup?.subcategory) {
      return menuLabels[categoryGroup?.subcategory];
    } else {
      return menuLabels[categoryGroup?.category];
    }
  };

  return (
    <Drawer
      size="large"
      destroyOnClose
      placement="right"
      closable={false}
      open={open}
      styles={{
        body: {
          padding: 0,
          paddingBottom: 113,
          paddingLeft: 1,
          borderRadius: 24,
          backgroundColor: "#f5f5fa",
        },
        content: { borderRadius: 24, backgroundColor: "#f5f5fa" },
        wrapper: {
          width: drawerWidth(),
          borderRadius: 24,
          backgroundColor: "#f5f5fa",
          margin: widthWindow > 767 ? "8.5px 8px" : 0,
          boxShadow: "none",
        },
      }}
      onClose={() => onChangeDrawer(false)}
    >
      <div className={styles.header}>
        <div className={styles.labelsBlock}>
          <p className={styles.label}>{countryCode?.longName}</p>
          <p className={styles.categoryLabel}>{t(`${getCategoryLabel()}`)}</p>
        </div>

        <div className={styles.buttonsBlock}>
          <Icon
            name="x"
            size={22}
            color="#5E626D"
            onClick={() => onChangeDrawer(false)}
          />
        </div>
      </div>
      {isLoading ? (
        <Skeleton count={13} className={styles.sceletons} />
      ) : (
        <div className={styles.drawer_container}>
          <div
            className={`${styles.content_atricle}`}
            dangerouslySetInnerHTML={{
              __html: article.split("<p><p/>")[0],
            }}
          />
        </div>
      )}

      <div className={styles.sticky_footer}>
        <div className={styles.article_sticky_footer}>
          <p className={styles.article_check}>{t("artificialIntelligenc")}</p>
        </div>
        <>{risksHistory}</>
      </div>
    </Drawer>
  );
};
