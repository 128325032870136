import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { createStyleSheet } from "../helpers";

export const Loader = () => {
  return (
    <Content style={styles.container}>
      <Spin size="large" />
    </Content>
  );
};

const styles = createStyleSheet({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
});
