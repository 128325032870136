import { useEffect } from "react";
import "./styles.css";
import logo from "@/assets/images/logo.svg";
import labelLogo from "@/assets/images/label-travy.svg";
import _ from "lodash";

import { risksConfig } from "@/config/risks.config";
import { Menu } from "../menu";
import { useRisksCategory } from "@/hooks/use-risks-category.hook";
import { CategoryRisks, SubcategoryRisks } from "@/typing/enums";
import { Icon } from "@/core/icons";

interface IProps {
  isOpen: boolean;
  toggleBar: (val: boolean) => void;
}
export const LeftSideBar = ({ isOpen, toggleBar }: IProps) => {
  const { active, getActiveCategory, setCategoryRisks } = useRisksCategory();

  useEffect(() => {
    getActiveCategory();
  }, []);

  const onClickRisk = (val: { category: string; subcategory?: string }) => {
    toggleBar(true);
    if (val?.category === CategoryRisks.Climate && !val.subcategory) {
      setCategoryRisks({
        category: val?.category,
        subcategory: SubcategoryRisks.Weather,
      });
    } else
      setCategoryRisks(
        val as {
          category: CategoryRisks;
          subcategory: SubcategoryRisks;
        }
      );
  };

  return (
    <div className={`left-sidebar ${isOpen ? "open" : ""}`}>
      <Icon
        className={isOpen ? "sidebar_toggle" : "hidden"}
        onClick={() => toggleBar(!isOpen)}
        name={"left-arrow"}
        size={16}
        color="#717375"
      />

      <div className="sidebar-content">
        <div
          className="logo_container"
          onClick={() =>
            onClickRisk({
              category: CategoryRisks.Owerview,
              subcategory: null,
            })
          }
        >
          <img src={logo} style={{ height: 30 }} />
          <img
            src={labelLogo}
            className={isOpen ? "logo_label" : "hide_logo_label"}
          />
        </div>

        <div>
          <>
            {risksConfig.map((it, index) => (
              <Menu
                id={`category-${index}`}
                indexMenu={index}
                activeCategory={active}
                onClickMenu={onClickRisk}
                key={it.key}
                item={it}
                close={_.noop}
              />
            ))}
          </>
        </div>
      </div>
    </div>
  );
};
