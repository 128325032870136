import { IMenu } from "@/core/components/menu/typing";
import { CategoryRisks, SubcategoryRisks } from "@/typing/enums";

export const categoriesIconConfig: any = {
  [CategoryRisks.Owerview]: "overview",
  [CategoryRisks.Conflicts]: "fire",
  [SubcategoryRisks.Geopolitical]: "fire",
  [SubcategoryRisks.InternalSocialTensions]: "fire",
  [SubcategoryRisks.PotentialDanger]: "fire",

  [CategoryRisks.Social]: "profile-user",
  [SubcategoryRisks.StandardOfLiving]: "profile-user",
  [SubcategoryRisks.WaterSupplies]: "profile-userr",
  [SubcategoryRisks.HumanCapitalDevelopment]: "profile-userr",

  [CategoryRisks.Medical]: "heart-1",
  [SubcategoryRisks.MedicalSituation]: "heart-1",
  [SubcategoryRisks.PandemicsAndEpidemics]: "heart-1",

  [CategoryRisks.Climate]: "flash",
  [SubcategoryRisks.Weather]: "flash",
  [SubcategoryRisks.SeaLevelRise]: "flash",

  [CategoryRisks.Requirements]: "book",
  [CategoryRisks.LGBTQ]: "lgbt",
  [CategoryRisks.Women]: "women",
};
export const risksConfig: IMenu[] = [
  {
    label: "conflicts",
    key: CategoryRisks.Conflicts,
    iconName: "fire",
    items: [
      {
        label: "geoConflicts",
        key: SubcategoryRisks.Geopolitical,
      },
      {
        label: "internalConflicts",
        key: SubcategoryRisks.InternalSocialTensions,
      },
      {
        label: "dangerPotential",
        key: SubcategoryRisks.PotentialDanger,
      },
    ],
  },
  {
    label: "social",
    key: CategoryRisks.Social,
    iconName: "profile-user",
    items: [
      {
        label: "standardLiving",
        key: SubcategoryRisks.StandardOfLiving,
      },
      {
        label: "foodDrinking",
        key: SubcategoryRisks.WaterSupplies,
      },
      {
        label: "humanCapitalDev",
        key: SubcategoryRisks.HumanCapitalDevelopment,
      },
    ],
  },
  {
    label: "medical",
    key: CategoryRisks.Medical,
    iconName: "heart-1",
    items: [
      {
        label: "medicalSituation",
        key: SubcategoryRisks.MedicalSituation,
      },
      {
        label: "pandemics",
        key: SubcategoryRisks.PandemicsAndEpidemics,
      },
    ],
  },
  {
    label: "climate",
    key: CategoryRisks.Climate,
    iconName: "flash",
    items: [
      { label: "weather", key: SubcategoryRisks.Weather },
      {
        label: "seaLevel",
        key: SubcategoryRisks.SeaLevelRise,
      },
    ],
  },

  {
    label: "LGBT",
    key: CategoryRisks.LGBTQ,
    iconName: "lgbt",
  },
  {
    label: "women",
    key: CategoryRisks.Women,
    iconName: "women",
  },
];

export const menuLabels: any = {
  [SubcategoryRisks.SeaLevelRise]: "seaLevel",
  [SubcategoryRisks.Geopolitical]: "geoConflicts",
  [SubcategoryRisks.InternalSocialTensions]: "internalConflicts",
  [SubcategoryRisks.PotentialDanger]: "dangerPotential",
  [SubcategoryRisks.StandardOfLiving]: "standardLiving",
  [SubcategoryRisks.WaterSupplies]: "foodDrinking",
  [SubcategoryRisks.HumanCapitalDevelopment]: "humanCapitalDev",
  [SubcategoryRisks.MedicalSituation]: "medicalSituation",
  [SubcategoryRisks.PandemicsAndEpidemics]: "pandemics",
  [SubcategoryRisks.Weather]: "weather",
  [CategoryRisks.Climate]: "climate",
  [CategoryRisks.Conflicts]: "conflicts",
  [CategoryRisks.Social]: "social",
  [CategoryRisks.Medical]: "medical",
  [CategoryRisks.Owerview]: "overview",
  [CategoryRisks.LGBTQ]: "LGBTQ",
  [CategoryRisks.Women]: "women",
};

export const risksColor: any = {
  h: "#EA6851",
  m: "#F8B405",
  l: "#33A452",
};
