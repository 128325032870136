import { useState } from "react";
import styles from "./styles.module.css";
import { Icon } from "../../icons";
import { Modal } from "antd";
import _ from "lodash";
import { risksConfig } from "@/config/risks.config";

import { useResizeWindow } from "@/hooks/use-resize-window.hook";
import { CategoryRisks, SubcategoryRisks } from "@/typing/enums";
import { useRisksCategory } from "@/hooks/use-risks-category.hook";
import { Menu } from "../menu";
import { PrimaryButton } from "../buttons";

export const MobileFilter = () => {
  const { width: windowWidth } = useResizeWindow();
  const [filter, setFilter] = useState<boolean>(false);

  const [risk, setRisk] = useState<{
    category: CategoryRisks;
    subcategory: SubcategoryRisks;
  }>(null);

  const toggleFilter = () => {
    setFilter(() => !filter);
  };

  const { active, setCategoryRisks } = useRisksCategory();

  const onClickRisk = (val: { category: string; subcategory?: string }) => {
    if (val?.category === CategoryRisks.Climate && !val.subcategory) {
      setRisk({
        category: val?.category,
        subcategory: SubcategoryRisks.Weather,
      });
    } else {
      setRisk(
        val as {
          category: CategoryRisks;
          subcategory: SubcategoryRisks;
        }
      );
    }
  };

  const apply = () => {
    setCategoryRisks(risk);
    setFilter(false);
  };

  if (windowWidth > 768) return null;
  return (
    <>
      <div
        className={styles.filter_btn}
        style={{
          right:
            active?.subcategory === SubcategoryRisks.Weather ||
            active?.subcategory === SubcategoryRisks.SeaLevelRise ||
            active?.category === CategoryRisks.Requirements
              ? -58
              : -109,
        }}
      >
        <Icon
          className={styles.icon_filter}
          onClick={toggleFilter}
          name={filter ? "x" : "menu"}
          size={24}
          color="#0F0E0E"
        />
        {active?.category && active?.category !== CategoryRisks.Owerview ? (
          <div className={styles.filter_indicator} />
        ) : null}
      </div>

      <Modal
        transitionName=""
        maskTransitionName=""
        closeIcon={null}
        footer={null}
        classNames={{
          content: styles.legen_modal,
          body: styles.modal_body,
        }}
        open={filter}
      >
        <div className={styles.legen_modal_content}>
          <p className={styles.title_modal}>Filters</p>
          <div>
            {risksConfig.map((it, index) => (
              <Menu
                indexMenu={index}
                activeCategory={risk}
                onClickMenu={onClickRisk}
                key={it.key}
                item={it}
                close={() => setFilter(false)}
              />
            ))}
          </div>
          <div className={styles.applly_btn}>
            <PrimaryButton
              label="Apply"
              onClick={apply}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          className={styles.close_modal}
          onClick={toggleFilter}
          style={{
            left:
              active?.subcategory === SubcategoryRisks.Weather ||
              active?.subcategory === SubcategoryRisks.SeaLevelRise ||
              active?.category === CategoryRisks.Requirements
                ? 10
                : 60,
          }}
        >
          <Icon
            className={styles.icon_filter}
            name="x"
            size={24}
            onClick={_.noop}
          />
        </div>
      </Modal>
    </>
  );
};
