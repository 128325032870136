import { useEffect, useMemo, useState } from "react";
import { MenuItem } from "./atoms";
import styles from "./styles.module.css";
import { IMenu } from "./typing";
import _ from "lodash";
import { Icon } from "@/core/icons";
import { CategoryRisks, Languages } from "@/typing/enums";
import { useTranslation } from "react-i18next";
import { useLanguageStore } from "@/store/language.store";

interface IProps {
  indexMenu: number;
  item: IMenu;
  onClickMenu: (val: { category: string; subcategory?: string }) => void;
  activeCategory: { category: string; subcategory?: string };
  id?: string;
  close: () => void;
}
export const Menu = ({
  item,
  onClickMenu,
  activeCategory,
  indexMenu,
  id,
}: IProps) => {
  const { t } = useTranslation();
  const { lang } = useLanguageStore();
  const isOpenBar: boolean = JSON.parse(localStorage.getItem("BAR"));

  const [prevCategory, setPrevCategory] = useState<CategoryRisks>();

  const [open, setOpen] = useState(false);

  const isActiveCategory = activeCategory?.category === item.key;

  const manageOpenMenu = () => {
    if (!isOpenBar) {
      setOpen(false);
    } else if (isActiveCategory && isOpenBar) {
      setOpen(true);
    } else if (
      activeCategory?.category === CategoryRisks.Climate &&
      prevCategory !== activeCategory?.category
    ) {
      setOpen(false);
    }
  };

  const manageCloseMenu = () => {
    if (
      prevCategory !== activeCategory?.category &&
      !activeCategory?.subcategory
    ) {
      setOpen(false);
    } else if (
      activeCategory?.category === CategoryRisks.Climate &&
      prevCategory !== activeCategory?.category &&
      isActiveCategory
    ) {
      setOpen(true);
    }
  };

  useEffect(() => {
    manageOpenMenu();
    manageCloseMenu();
  }, [activeCategory, isOpenBar, prevCategory]);

  const toggleOpen = () => {
    onClickMenu({ category: item.key });
    setPrevCategory(item.key as CategoryRisks);
    setOpen((prev) => !prev);
  };

  const onClickItem = (val: string) => {
    onClickMenu({ category: item.key, subcategory: val });
  };

  const languageHeightMenu: any = [
    {
      [Languages.EN]: 105,
      [Languages.FR]: 125,
      [Languages.DE]: 125,
      [Languages.ES]: 125,
    },
    {
      [Languages.EN]: 140,
      [Languages.FR]: 165,
      [Languages.DE]: 140,
      [Languages.ES]: 140,
    },
    {
      [Languages.EN]: 85,
      [Languages.FR]: 85,
      [Languages.DE]: 85,
      [Languages.ES]: 65,
    },
    {
      [Languages.EN]: 70,
      [Languages.FR]: 80,
      [Languages.DE]: 85,
      [Languages.ES]: 80,
    },
  ];
  const heightList = (ind: number) => {
    if (!open) {
      return 0;
    } else if (open && ind == 0) {
      return languageHeightMenu[0][lang];
    } else if (open && ind == 1) {
      return languageHeightMenu[1][lang];
    } else if (open && ind == 2) {
      return languageHeightMenu[2][lang];
    } else if (open && ind == 3) {
      return languageHeightMenu[3][lang];
    }
    return 110;
  };

  const heightSideLine = (ind: number) => {
    if (!open) {
      return 0;
    } else if (open && ind == 0) {
      return 95;
    } else if (open && ind == 1) {
      return 125;
    } else if (open && ind == 2) {
      return 70;
    } else if (open && ind == 3) {
      return 60;
    }
  };

  const list = useMemo(() => {
    if (!item?.items || (!isOpenBar && !activeCategory?.subcategory))
      return null;
    return (
      <div
        style={{ height: heightList(indexMenu) }}
        className={
          !open
            ? `${styles.list_items} ${styles.collapsed}`
            : `${styles.list_items}`
        }
      >
        <div
          className={styles.line}
          style={{ height: heightSideLine(indexMenu) }}
        />
        {item?.items.map((it, index) => (
          <MenuItem
            isActive={activeCategory?.subcategory === it.key}
            key={it.key}
            index={index}
            item={it}
            onClick={() => onClickItem(it.key)}
          />
        ))}
      </div>
    );
  }, [isOpenBar, open, activeCategory, prevCategory, indexMenu]);

  const arrowMenu = () => {
    if (!item?.items || !isOpenBar) return null;
    return (
      <div
        className={`${styles.arrow} ${
          isActiveCategory && open ? styles.up : styles.down
        } ${isOpenBar ? styles.show : ""}`}
      >
        <Icon
          name="arrow-up"
          size={20}
          color={isActiveCategory ? "#0F0E0E" : "#717375"}
        />
      </div>
    );
  };

  return (
    <div className={`${styles.collapsible_list}`}>
      <div id={id} onClick={toggleOpen} className={styles.collapseble_item}>
        <div
          className={styles.menu_label_container}
          style={{ display: isOpenBar ? "flex" : "block" }}
        >
          <Icon
            onClick={_.noop}
            name={item.iconName}
            size={14}
            color={isActiveCategory ? "#FFFFFF" : "#0F0E0E"}
            style={{
              backgroundColor: isActiveCategory ? "#0D8F5B" : "#FFFFFF",
            }}
            className={styles.icon_category}
          />

          <p
            style={{
              color: "#0F0E0E",
            }}
            className={!isOpenBar ? styles.collapsed : styles.label_menu}
          >
            {t(`${item?.label}`)}
          </p>
        </div>
        <>{arrowMenu()}</>
      </div>
      <>{list}</>
    </div>
  );
};
