import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainPage } from "./root/main.page";
import i18n from "@/i18n";
import "App.css";
import { useLanguageStore } from "./store/language.store";
import { Languages } from "./typing/enums";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { getParams } from "./helpers/get-params.herler";
import { SecurePage } from "./root/secure-page";
import { Helmet, HelmetProvider } from "react-helmet-async";
import countriesSEO from "@/countries.json";
import countriesCategories from "@/micromarkup-config.json";

import _ from "lodash";

function App() {
  const { lang, setLang } = useLanguageStore();
  const langParam = getParams(1);

  const getLanguage = () => {
    if (!langParam) {
      setLang(Languages.EN);
    } else {
      setLang(langParam as Languages);
    }
  };

  useEffect(() => {
    getLanguage();
  }, [lang, langParam]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const apiKey = getParams(0);
  if (_.size(apiKey) !== 25) return <SecurePage />;
  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(countriesSEO)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(countriesCategories)}
          </script>
        </Helmet>
        <Router>
          <Routes>
            <Route
              path="/:apiKey?/:lang?/:category?/:subCategory?/:options?"
              element={<MainPage />}
            />
          </Routes>
        </Router>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default App;
