import { createStyleSheet } from "@/core/helpers";
import logo from "@/assets/images/full-logo.svg";

export const SecurePage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <img src={logo} />
        <p style={styles.title}>Oops! You need an API key</p>
      </div>
    </div>
  );
};

const styles = createStyleSheet({
  container: {
    height: "100dvh",
    width: "100dvw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  title: {
    fontSize: 20,
  },
});
