import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { appEvents } from "@/core/events";
import { useCategoryRiskStore } from "@/store/category-risk.store";
import { useResizeWindow } from "@/hooks/use-resize-window.hook";
import { useEventsListener } from "@/hooks/use-listener.hook";
import { SubcategoryRisks } from "@/typing/enums";
import { Icon } from "@/core/icons";

export const SeaLevelBtn = () => {
  const [show, setShow] = useState(true);
  const { active } = useCategoryRiskStore();
  const { width: widthWindow } = useResizeWindow();

  useEventsListener(
    "seaLevel",
    (data) => {
      setShow(data?.isShow);
    },
    [show]
  );

  useEffect(() => {
    appEvents.emit("seaLevel", { isShow: show, level: -20 });
  }, [show]);

  const handleClick = () => {
    setShow((prev) => !prev);
  };

  return (
    <div
      className={styles.filter_btn}
      style={{
        display:
          widthWindow <= 767 &&
          active?.subcategory === SubcategoryRisks.SeaLevelRise
            ? "flex"
            : "none",
      }}
      onClick={handleClick}
    >
      <Icon className={styles.icon_sea} name={show ? "x" : "waves"} size={24} />
    </div>
  );
};
