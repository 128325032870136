import { IWeatherForecastDayResponse } from "../../api/interfaces";
import { ForecastItemAtom } from "./atoms";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/de";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import { useLanguageStore } from "@/store/language.store";

interface IProps {
  forecast: IWeatherForecastDayResponse[];
}
export const Forecast = ({ forecast }: IProps) => {
  const { lang } = useLanguageStore();
  dayjs.locale(lang);

  const formatDate = (date: string, format: string) => {
    const formattedDate = dayjs(date).format(format);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };
  return (
    <div className={styles.container}>
      {forecast.map((it) => {
        return (
          <ForecastItemAtom
            humidity={it?.day.avghumidity}
            date={dayjs(it.date).format("DD.MM")}
            day={formatDate(it.date, "dddd")}
            temp_c={+it?.day.avgtemp_c.toFixed(0)}
            icon={it?.day.condition.icon}
          />
        );
      })}
    </div>
  );
};
