import { RiskLevel } from "@/typing/enums";

export const mapStyleConfig: any = {
  [RiskLevel.Hight]: {
    strokeColor: "#D6EDFF",
    strokeOpacity: 1.0,
    fillColor: "#EA6851",
    fillOpacity: 1,
  },
  [RiskLevel.Middle]: {
    strokeColor: "#D6EDFF",
    strokeOpacity: 1.0,
    fillColor: "#F8B405",
    fillOpacity: 1,
  },
  [RiskLevel.Low]: {
    strokeColor: "#D6EDFF",
    strokeOpacity: 1.0,
    fillColor: "#33A452",
    fillOpacity: 1,
  },
  ["default"]: {
    strokeColor: "#D6EDFF",
    strokeOpacity: 1.0,
    fillColor: "#BCBCBC",
    fillOpacity: 1,
  },
};

export const risksColor: any = {
  h: "#EA6851",
  m: "#F8B405",
  l: "#33A452",
};

export const riskLabels: any = {
  h: "High",
  m: "Medium",
  l: "Low",
  ns: "Not specified",
};

export const countriesIds: any = {
  ChIJCzYy5IS16lQRQrfeQ5K5Oxw: "l",
  ChIJ2WrMN9MDDUsRpY9Doiq3aJk: "m",
  ChIJU1NoiDs6BIQREZgJa760ZO0: "l",
  ChIJjw5wVMHZ0UAREED2iIQGAQA: "h",
  ChIJa2JP5tcMREARwkotEmR5kE8: "m",
  ChIJgUit4oQl2kYREIzsgdGhAAA: "m",
  "ChIJ-yRniZpWPEURE_YRZvj9CRQ": "m",
  ChIJjcVRlmGICw0Rw_8sxIGT09k: "m",
  "ChIJlYCJ8t8dV0cRXYYjN-pQXgU": "m",
  ChIJ3fYyS9_KgUYREKh1PNZGAQA: "l",
  "ChIJ8fA1bTmyXEYRYm-tjaLruCI": "l",
  "ChIJv-VNj0VoEkYRK9BkuJ07sKE": "l",
  ChIJ_UuggpyUkkYRwyW0T7qf6kA: "l",
  ChIJ_ZqKe2cw6UYREPzyaM3PAAA: "l",
  ChIJYXNnvXg55xARnIDAzluDYDs: "l",
  ChIJoWm3KDZ8yUARy6xT36wZgSU: "l",
  "ChIJifBbyMH-qEAREEy_aRKgAAA": "l",
  "ChIJw-Q333uDQUcREBAeDCnEAAA": "l",
  ChIJ38WHZwf9KysRUhNblaFnglM: "l",
  ChIJcSZPllwVsBQRKl9iKtTb2UA: "m",
  ChIJp8Y8QdzmGBURIPYqiPRGdYc: "h",
  ChIJraoihAIXHxURcPkAbAk0fcw: "m",
  ChIJi8mnMiRJABURuiw1EyBCa2o: "h",
  ChIJmd5kZkdvABURmU4mUQdbKI0: "m",
  "ChIJQSqV5z-z5xURm7YawktQYFk": "l",
  ChIJEUr1VD2CVxURAhYGorthpW0: "m",
  ChIJ8R1rwS7s9z4RzvpRntOVsEA: "h",
  ChIJVU1JymcX3hQRbhTEf4A8TDI: "m",
  ChIJx7pz54RWFUARIy2C63p1tNA: "m",
  ChIJoVHqvj82xT8R0u3Yks1rcnQ: "l",
  ChIJg1vR7KcuHRgRvuXpXM46OZ4: "h",
};
