import { ArticleWidget } from "@/article/widgets";
import { LayoutPage } from "@/core/components";
import { isNewCategory } from "@/helpers/new-category.helper";
import { useGetCountries } from "@/hooks/use-get-countries.hook";
import { useRisksCategory } from "@/hooks/use-risks-category.hook";
import { CategoryRisks, SubcategoryRisks } from "@/typing/enums";
import { DetailedWeather } from "@/weather";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type Params = {
  category?: CategoryRisks;
  subCategory?: SubcategoryRisks;
  options: any;
};
export const MainPage = () => {
  const params = useParams<Params>();
  const defValue = localStorage.getItem("BAR");
  const [isOpen, setIsOpen] = useState(JSON.parse(defValue || "true"));
  const [openAtricle, setOpenArticle] = useState(false);
  const { active, setCategoryRisks } = useRisksCategory();
  const [countryCode, setCountryCode] = useState<{
    code: string;
    longName: string;
  }>(null);
  const { countriesPlaceIds, getOwerallRisks, countryList } = useGetCountries();

  useEffect(() => {
    getOwerallRisks();
  }, []);

  useEffect(() => {
    setCategoryRisks({
      category: params?.category,
      subcategory: params?.subCategory,
    });
  }, [params]);

  useEffect(() => {
    if (_.isNil(defValue)) {
      localStorage.setItem("BAR", JSON.stringify(true));
    }
  }, [defValue]);

  const toggleSidebar = (val: boolean) => {
    setIsOpen(val);
    localStorage.setItem("BAR", String(val));
  };

  const handleChangeDrawer = (val: boolean) => setOpenArticle(val);

  const handleChangeCode = (val: { code: string; longName: string }) => {
    setCountryCode(val);
    setOpenArticle(!!active?.subcategory || isNewCategory(active?.category));
  };
  return (
    <>
      <DetailedWeather />

      <LayoutPage
        toggleBar={toggleSidebar}
        hideSidebar={!!params?.options}
        countriesPlaceIds={countriesPlaceIds}
        handleChangeCode={handleChangeCode}
        isOpenBar={isOpen}
      />
      {countryList?.some((it) => it?.countryCode === countryCode?.code) ? (
        <ArticleWidget
          categoryGroup={active}
          open={openAtricle}
          onChangeDrawer={handleChangeDrawer}
          countryCode={countryCode}
        />
      ) : null}
    </>
  );
};
