import styles from "./styles.module.css";
import { useEffect } from "react";
import fullLogo from "@/assets/images/full-logo.svg";
import { Icon } from "../../icons";
import { categoriesIconConfig, menuLabels } from "@/config/risks.config";
import { useTranslation } from "react-i18next";
import { useRisksCategory } from "@/hooks/use-risks-category.hook";
import { CategoryRisks } from "@/typing/enums";

export const MobileHeader = () => {
  const { t } = useTranslation();
  const { active, getActiveCategory, setCategoryRisks } = useRisksCategory();

  useEffect(() => {
    getActiveCategory();
  }, []);
  console.log("active", active);
  const getLabel = () => {
    if (!active?.category) {
      return menuLabels[CategoryRisks.Owerview];
    } else if (active?.category && active?.subcategory) {
      return menuLabels[active?.subcategory];
    } else {
      return menuLabels[active?.category];
    }
  };

  const getIconCategory = () => {
    if (!active?.category) {
      return categoriesIconConfig[CategoryRisks.Owerview];
    } else if (active?.category && active?.subcategory) {
      return categoriesIconConfig[active?.subcategory];
    } else {
      return categoriesIconConfig[active?.category];
    }
  };
  console.log("icon", getIconCategory());
  return (
    <div className={styles.container}>
      <div className={styles.header_row}>
        <img
          className={styles.logo_guido}
          src={fullLogo}
          onClick={() => {
            setCategoryRisks({
              category: CategoryRisks.Owerview,
              subcategory: null,
            });
          }}
        />
      </div>

      <div className={styles.action_header_container}>
        <div className={styles.category_row}>
          <Icon
            className={styles.icon_category}
            name={getIconCategory()}
            size={14}
            color="#FFFFFF"
          />
          <p className={styles.category_label}>{t(`${getLabel()}`)}</p>
        </div>
      </div>
    </div>
  );
};
