import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationEN } from "./en";
import { translationFR } from "./fr";
import { translationDE } from "./de";
import { translationES } from "./esp";
import { Languages } from "@/typing/enums";

const languageConfig = {
  [Languages.EN]: translationEN,
  [Languages.FR]: translationFR,
  [Languages.DE]: translationDE,
  [Languages.ES]: translationES,
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: languageConfig["en"],
    },
    fr: { translation: languageConfig["fr"] },
    de: { translation: languageConfig["de"] },
    es: { translation: languageConfig["es"] },
  },
  lng: Languages.EN,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
